import React, { useState } from "react";
import "./style.css";
import ContactModal from "../ContactModal"
import { send_message } from "./contact";
import Icon from "../../../assest/Icons";

function Index() {
  const initialValues = {
    fullName: "",
    to: "info@vendexpress.ng",
    phoneNumber: "",
    message_body: "",
    location: "",
    from: "",
    subject: "Vend Express Contact Form"
  };
  const [formValues, setField] = useState(initialValues);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)


  const handleChange =  (e) => {
    const value = e.target.value;
    setField({
      ...formValues,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    formValues.message_body = `${formValues.message_body}, ${formValues.location ? `- ${formValues.location}` : ""}`
    send_message(formValues, (success)=>{
      if(success) setShow(true);
      setLoading(false)
    })
  };
  
  return (
    <React.Fragment>
      <div className="contact-form-wrap">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-ctrl">
            <label>Full Name</label>
            <input
              name="fullName"
              type="text"
              value={formValues["fullName"]}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-ctrl">
            <label>Phone Number</label>
            <input
              name="phoneNumber"
              type="tel"
              value={formValues["phoneNumber"]}
              onChange={handleChange}
              pattern='[0-9]{10,11}'
              title="Eg 080xxxxxxxx2"
              required
            />
          </div>
          <div className="form-ctrl">
            <label>Email</label>
            <input
              name="from"
              type="email"
              value={formValues["from"]}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-ctrl">
            <label>Location</label>
            <input
              name="location"
              value={formValues["location"]}
              onChange={handleChange}
              type="text"
            />
          </div>
          <div className="form-ctrl">
            <label>Write a Message</label>
            <textarea
              name="message_body"
              value={formValues["message_body"]}
              onChange={handleChange}
            />
          </div>
          <div className="send-btn">
            <button type="submit" className="primary-btn">
              {
              loading &&
               <span className="spinner">
                <Icon type={'loader'}/>
               </span>
              }
                Send
            </button>
          </div>
        </form>
      </div>
        <ContactModal
          show = {show}
          onClose={() => setShow(false)}
         />
    </React.Fragment>
  );
}

export default Index;
