const faqs = [
    {
        label: "How do I create a Vend Express account?", 
        response:'Simply click on the "Sign Up" option and register with your full name, email address, phone number and password.'
    },
    {
        label: "How do I book a rider?", 
        response:`After signing in to the website, simply fill in your pickup and dropoff locations, and the estimated price for your delivery would pop up.
        Then you can confirm your order by clicking on "Book".`
    },
    {
        label: "Can I track my deliveries?", 
        response:`Yes, you can. 
        Your deliveries would have a tracking number for you to be able to track via the "SEARCH" box. `
    },
    {
        label: "Can I book ahead of time?", 
        response:`Yes, you can book your deliveries ahead of time. 
        From the Home Page, simply fill in the pickup and dropoff details of your delivery, along with the date and time of the day you intend for the delivery to be made.
        Then click on "Book". `
    },
    {
        label: "Do you offer services outside Benin-City?", 
        response:`No, for now, we don't. 
        We intend to expand to locations outside Benin-City soon.`
    },
    {
        label: "What packages can I send through Vend Express Logistics?", 
        response:`You can send handy packages of all kinds, including properly-sealed food, drinks, snacks, cakes, surprise packages, documents, groceries, laundry, etc.`
    },
    {
        label: "How can I check the price rates for my deliveries? ", 
        response:`Simply input your pick up location and drop off location and your estimated price and ETA (Estimated Time of Arrival) would pop up.`
    },
    {
        label: "What are your opening and closing hours?", 
        response:`We are open to receive delivery orders from 8am to 6pm, every Monday to Saturday. `
    },
    {
        label: "What are the payment options available?", 
        response:`You could make payments through any of the two options available. 
        You can either Pay Cash on Delivery or Pay via your Debit Card here on the website.`
    },
    {
        label: "Can I cancel my order?", 
        response:`You can choose the CANCEL option on the Booking page during the booking process.
        But note that ONLY orders that have not been sent out can be canceled.  
        You CANNOT cancel an order that has already been sent out and charged but you can reroute it to a different location. `
    },
    {
        label: "Can I book a delivery for someone else?", 
        response:`Yes, you can via the "Waybill or Bus Park Delivery" option. 
        We could pick up or drop off your packages to or from any bus park going out or coming in to Benin-City. `
    },
    {
        label: "Can I send or pickup items from outside Benin-City through Vend Express Logistics?", 
        response:`Yes, you can. 
        You just simply need to fill in THEIR details in the provided boxes.`
    },
    {
        label: "Can I book one rider to deliver multiple packages for my business all over the city?", 
        response:`Yes, you can, but only through the website and at our discretion. 
        You are not permitted to book the Riders directly or outside the scope of the website.`
    },
]

export const getFaqs = (itemCount = faqs.length) => {
    const result = [];
    for (let i = 0; i < itemCount; i++) {
        result.push(faqs[i])
    }
    return result
}