import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Hero from './Hero';
import Services from './Services';
import Rider from './Rider';
import Testimonials from './Testimonials';
import MobileApp from './MobileApp';
import Faq from "./Faq"


function Index() {
    return (
        <div className='layout'>
            <Navbar/>
            <div className='content'>
            <div className='bg-light-green'>
            <Hero />
            <Services />
            </div>
            <Rider />
            <Testimonials />
            <MobileApp />
            <Faq/>
            </div>
            <Footer/>
        </div>
    )
}

export default Index;