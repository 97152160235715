import React from 'react';
import "./style.css";
import Parcel from "../../../assest/images/vendex-package.png";
import Partnerships from "../../../assest/images/hand-shake.png";
import Food from "../../../assest/images/burger.png";
import { Link } from 'react-router-dom';



function Index() {
    const items = [
        {img: Parcel, label: "Instant Parcel Delivery"},
        {img: Partnerships, label: "Partnerships"},
        {img: Food, label: "Food/Restaurant Delivery"}
    ]
    return (
        <div className='container service-delivery'>
            <div className='delivery-wrap content-wrapper'>
                <h1>First Class Delivery Service</h1>
                <div className='flex'>
                { items.map((item, i) => {
                    return (
                        <div className='card hover' key={i}>
                            <div className='card-img img-wrap'>
                                <img src={item.img} alt={item.label} />
                            </div>
                            <div className='card-desc flex'>
                            {item.label}
                            </div>
                         </div>
                    )
                })}
                </div>
                <div className='more'>
                <Link to={"/services"} > See more services</Link>
                </div>
            </div>
        </div>
    )
}

export default Index
