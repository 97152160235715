import React from 'react';
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import WorkWithUs from "./WorkWithUs";
import Apply from "./Apply";
import CoreValues from "./CoreValues";


function Index() {
    return (
        <div className='layout'>
            <Navbar />
            <div className='content'>
                <WorkWithUs />
                <Apply />
                <CoreValues />
            </div>
            <Footer />
        </div>
    )
}

export default Index
