import React from 'react'
import "./style.css"
import customerImg from "../../../assest/images/customer.png"

function Index() {
    return (
        <div className='container about-container'>
        <div className='about-wrap content-wrapper'>
            <div className='flex'>
              <div className='text-wrap'>
                    <h1>Who we are</h1>
                    <p>Vend Express Logistics is your number one trusted 
                        logistics partner for errands and deliveries all over Benin-City.
                    </p>
                    <p>At Vend Express Logistics, we are committed to the safe, secure and speedy 
                        delivery of all kinds of packages within and around Benin-City. 
                    </p>
                    <p>
                    We are a licensed Logistics Company, currently based 
                    in Benin-City, with plans to expand beyond the city and state of Edo, Nigeria. 
                    </p>
                    <p>
                    We make use of easy order processing, professional riders, seamless payment process and affordable 
                    rates in ensuring the highest quality of service delivery to our customers
                    </p>
                    <p>
                    Our riders are carefully selected and trained by the best to guarantee the quick,
                     efficient and safe delivery of your packages to your doorstep. 
                    </p>
                </div>
                <div className='img-wrap'>
                    <img src={customerImg} alt='vendex rider with a customer' />
                </div>
              </div>
            </div>
            </div>
    )
}

export default Index
