import React from 'react';
import "./style.css";
import { getFaqs } from '../faqHelper';

function Index({itemCount}) {
   
    return (
        <div className='faqs'>
           {
            getFaqs(itemCount).map((faq, index)=>{
                return (
                    <div className='faq-card' key={index}>
                    <div className='heading bg-dark-green faq-flex'>
                    {faq.label} 
                    </div>
                    <div className='body bg-light-green faq-flex'>
                        {faq.response}
                    </div>
                </div>
                )
            })
           }
        </div>
    )
}

export default Index
