import React from 'react';
import excellenceImg from "../../../assest/images/hand+star.svg";
import professionalImg from "../../../assest/images/man+suit.svg";
import innovationImg from "../../../assest/images/bulb.svg";
import serviceImg from "../../../assest/images/headset.svg";
import integrityImg from "../../../assest/images/balance.svg";
import "./style.css";

function Index() {
    const items = [
        {label: "Excellence" , img: excellenceImg},
        {label: "Professionalism" , img: professionalImg},
        {label: "Integrity" , img: integrityImg},
        {label: "Innovation" , img: innovationImg},
        {label: "Customer Service" , img: serviceImg},
    ]
    return (
        <div className='container career-core bg-light-green'>
            <div className='content-wrapper'>
            <div className='career-core-header'>
            <div className='title'>
            Our Core Values
            </div>
            </div>
            <div className='core-flex'>
                {
                    items.map((item) =>{
                        return(
                            <div key={item.label} className="flex-wrap">
                                <div>
                                    <img src={item.img} alt={item.label} />
                                </div>
                                <p className='core-label'>{item.label}</p>
                            </div>
                        )
                    })
                }

            </div>
        </div>
        </div>
    )
}

export default Index
