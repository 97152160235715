import React from "react";
import excellenceImg from "../../../../assest/images/hand+star.svg"
import professionalImg from "../../../../assest/images/man+suit.svg"
import innovationImg from "../../../../assest/images/bulb.svg"
import serviceImg from "../../../../assest/images/headset.svg"
import integrityImg from "../../../../assest/images/balance.svg"
import "./style.css"

function Index() {
  const coreValues = [
    {
      label: "Excellence",
      icon: excellenceImg,
      description:
        "At Vend Express Logistics, we are driven by the pursuit of excellence as a means of consistently guaranteeing customer satisfaction. Our methods and procedures are geared towards improving the quality of the lives of our customers.",
    },
    {
      label: "Professionalism",
      icon: professionalImg,
      description:
        "All our staff members across all levels are deeply ingrained with the culture of professionalism in all that we do. You can be sure of the best professional experience with Vend Express Logistics.",
    },
    {
      label: "Integrity",
      icon: integrityImg,
      description:
        " Integrity is at the very core of what we do at Vend Express Logistics. You can be sure of the safety, security and propriety of your packages, personal information and delivery process. You can trust us. ",
    },
    {
      label: "Innovation",
      icon: innovationImg,
      description:
        "Every of our effort at Vend Express Logistics is intentionally led by innovation. We constantly strive to provide innovative solutions to your logistics management experience. We are result-oriented as we seek to provide you with the best possible delivery service.",
    },
    {
      label: "Customer Service",
      icon: serviceImg,
      description:
        "Our number one priority at Vend Express Logistics is Customer Service and Customer Satisfaction. We believe that our customers are our greatest assets and their happiness should be at the front-burner of our activities. ",
    },
  ];
  return (
    <div className="core-values">
      {coreValues.map((value) => {
        return (
          <div key={value.label} className="values">
            <div className="core-value-flex">
              <div className="img-wrap">
                <img src={value.icon} alt={value.label} />
              </div>
            <div className="text-wrap">
            <div className="core-title">{value.label}</div>
              <div className="core-desc">{value.description}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Index;
