import React from 'react';
import ridersImg from "../../../assest/images/riders1.png"
import "./style.css"

function History () {
    return (
        <div className='container history-container bg-blue'>
        <div className='history-wrap content-wrapper'>
            <div className='flex'>
            <div className='img-wrap'>
                    <img src={ridersImg} alt='vendex riders' />
                </div>
              <div>
              <div className='text-wrap'>
                    <h1>Our History</h1>
                    <p>Vend Express Logistics officially began operations
                         on the 13th of May, 2022 with just 2 bikes.
                    </p>
                    <p>As of July 2022, we currently have 10 bikes and have had
                         the pleasure of completing close to 600 deliveries and counting.
                    </p>
                    <p>Our greatest joy at Vend Express Logistics is derived from seeing our customers happy and satisfied and 
                        we look forward to serving you better with each successful delivery. 
                    </p>
                    <p>Our tagline, <span className='green-txt'>"WE DEY FOR YOU"</span> represents our heart towards our customers and just how
                         much we prioritize customer satisfaction at Vend Express Logistics.
                    </p>
                    <p>
                        <span>Looking for a fast service?</span>
                        <a href="tel:09165656968">Just Call Us</a>
                    </p>
                    </div>
              </div>
            </div>
            </div>
        </div>
    )
}

export default History
