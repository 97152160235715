import React from 'react'
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import Terms from "./Terms"


function Index() {
    return (
        <div className='layout'>
            <Navbar />
            <div className='content content-wrapper'>
                <Terms />
            </div>
            <Footer />
        </div>
    )
}

export default Index
