import React from "react";
import "./style.css";
import privacyImg from "../../../assest/images/privacy.png";

function Index() {
  return (
    <div className="container terms">
      <div className="terms-header">
        <div className="title">Privacy Policy</div>
        <div className="img-cont">
          <img src={privacyImg} alt="privacy icon" />
        </div>
      </div>
      <div>
        <ol className="terms-list">
          <li>
            <p>
              Vend Express Logistics, and its subsidiaries* (“Vend Express
              Logistics” or “we”, or “our” or “us”), are committed to protecting
              your personal information and respecting your privacy.
            </p>
            <p>
              Vend Express Logistics will never sell, rent or disclose your
              personally identifiable information to outside parties for their
              own marketing purposes without your consent.
            </p>
          </li>
          <li>
            <p>
              We may disclose information to third parties in order to provide
              timely services to you, or related to services that you may have
              requested for, as further described in this policy.
            </p>
          </li>
          <li>
            <p>
              With your permission, we may send you e-mail regarding our new
              products, upgrades, or select third party products. We do not
              disclose your personally identifiable information to third
              parties.
            </p>
          </li>
          <li>
            <p>
              Vend Express Logistics only collects personal information which is
              reasonably necessary for its dealings with the individuals to whom
              the information relates.
            </p>
            <p>
              “Personal Information” means any information relating to an
              identified individual, or to an individual who can be identified,
              directly or indirectly, by reference to an identification number
              or to one or more factors specific to his or her physical,
              physiological, mental, economic, cultural or social identity.
            </p>
            <p>
              Personal identifiers, such as names, contact details (e.g. email
              address), customer’s payment details for the purpose of processing
              the purchase of our products and services, etc.
            </p>
          </li>
          <li>
            <p>
              Generally, Vend Express Logistics collects personal information
              directly from the individuals concerned. For instance, there are
              times when we may need to collect information from you through our
              Website. This generally involves filling out forms so you can
              download or order a Vend Express Logistics rider or third party
              product from our Website.
            </p>
            <p>
              When you make a request or contact us, we require you to fill out
              a form with your name and email address.
            </p>
          </li>
          <li>
            <p>
              Vend Express Logistics may also collect personal information about
              individuals indirectly from third parties, such as from our
              business partners, from third party providers of marketing lists,
              and through various other methods, including:
            </p>
            <p>- Cookies <br/> - Referrers <br/> - IP addresses <br/> - System information</p>
            <p>
              None of these methods can be used to identify, or reasonably
              identify, you with the exception of cookies, which can optionally
              store information that you may enter in forms on our Web Sites, as
              described below.
            </p>
          </li>
          <li>
            <p>
              A "cookie" is an element of data that a Website can send to your
              browser, which may then store it on your system. It can be used to
              provide you with a tailored user experience. Cookies are used to
              better personalize the content, banners and promotions that you
              and other users will see. We may use the information to research
              and understand how our users’ habits in the aggregate are similar
              or different so that we can enhance your experience on our
              website.
            </p>
          </li>
          <li>
            <p>
              With your consent, cookies can optionally store information from
              forms that you fill out on our site so that next time you visit,
              you do not have to re-enter this information. For example, this
              would allow you to enter your location so that next time you
              visit, you can automatically see riders from your area.
            </p>
            <p>
              You can set your browser to notify you when you receive a cookie,
              giving you the chance to decide whether or not to accept it. Vend
              Express Logistics does not require that you accept cookies.
            </p>
          </li>
          <li>
            <p>
              A "Referrer" is the information passed along by a web browser that
              references the Web URL you linked from, and is automatically
              collected by our Web server. This information is collected and
              used by us to understand the web sites referring traffic to our
              download pages and to present appropriate content to our potential
              customers. Referrer information is tabulated by Vend Express
              Logistics on an aggregated basis to identify general trends and
              traffic patterns.
            </p>
          </li>
          <li>
            <p>
              From time to time, we may enter into agreements with content
              partners that require Vend Express Logistics to count the number
              of product downloads and/or product sales stemming from a referral
              from a content partner site. This information is collected, used
              and aggregated by us to fulfil the reporting requirements of those
              agreements.
            </p>
            <p>
              This information may also be combined with the referrer
              information described above and tabulated on an aggregated basis
              to identify general trends and traffic patterns.
            </p>
          </li>
          <li>
            <p>
              At the time of booking or purchase, the information you provide to
              Vend Express Logistics, including personal information and
              referring URL’s, is collected in our web server logs and customer
              database and is kept strictly confidential and not shared with
              third parties.
            </p>
          </li>
          <li>
            <p>
              System information we gather includes time, type of web browser
              being used, the operating system/platform, and CPU speed. This
              information is sent automatically by your Web browser when you are
              connected to a website and is used by Vend Express Logistics only
              for broad demographic statistics. This information is used by us
              in identifying broad demographic trends and may be used to provide
              information appropriate for your computer system. You will not be
              personally identified from this information.
            </p>
          </li>
          <li>
            <p>
              Please note that third parties (including, for example,
              advertising networks and providers of external services like web
              traffic analysis services) may also use cookies, over which we
              have no control. These cookies are likely to be
              analytical/performance cookies or targeting cookies
            </p>
            <p>
              You block cookies by activating the setting on your browser that
              allows you to refuse the setting of all or some cookies.
            </p>
            <p>
              Note: However, if you use your browser settings to block all
              cookies (including essential cookies) you may not be able to
              access all or parts of our site.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Index;
