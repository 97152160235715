import React from 'react'
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import Services from "./Services"


function Index() {
    return (
        <div className='layout'>
            <Navbar />
            <div className='content'>
                <Services />
            </div>
            <Footer />
        </div>
    )
}

export default Index
