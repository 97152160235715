import React from 'react'
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import FaqHeader from "./component/FaqHeader"
import FaqCard from "./component/FaqCard"
import './style.css'



function Index() {
    return (
        <div className='layout'>
            <Navbar />
            <div className='content'>
                <div className='container faq content-wrapper'>
                    <div className='faq-header-container'>
                    <FaqHeader />
                    </div>
                    <div className='faq-list'>
                    <FaqCard />
                    </div>
                    <div className='faq-support'>
                        <p>Do you think that your question is not in this list?</p>
                        <span className='support-mail-wrap'>
                        Contact us via our Support Team or email us at &nbsp;
                        <span className='support-mail'>info@vendexpress.com</span>. 
                        </span>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Index
