import axios from "axios";

export const send_message = async (pageRequest, onFinish)  => {
    let url = `https://tapi.user.ng/notification/vendexpress/api/send-mail`;
    try {
      const response = await axios.post(url, pageRequest);
      const data = response.data;
      if (data.success || data.message) {
        onFinish(true);
      }
    }
    catch (error) {
      if(error){
        onFinish(false)
      }
    }
  }

 