import React, { useEffect, useRef } from 'react';
import Icon from '../../assest/Icons';
import "./style.css"

const Modal = ({ modalStyle, children, show, onClose, backdropStyle }) => {
    const modalRef = useRef(null);
    useEffect(
        () => {
            if (show) {
                modalRef.current.classList.add("visible");
            }
            else {
                modalRef.current.classList.remove("visible");
            }
        },
        [
            show
        ]
    );
    return (
        <React.Fragment>
            <div ref={modalRef} style={backdropStyle} className="modal-wrap">
                
                <div className="modal" style={modalStyle}>
                <button
                    onClick={onClose}
                    style={{ position: 'absolute', top: "0", right: "0" }}
                    className="close-btn"
                >
                   <Icon type={'close'}/>
                </button>
                    {children}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Modal;