import React from 'react'
import "./style.css"
import Icon from '../../../assest/Icons'

function Index() {
    return (
        <div className='contact-address'>
            <div className='address-wrap'>
            <Icon type="mapMarker"/>
                <div className='title'>Address</div>
                <div>
                    <p>
                10, Guobadia Avenue, Off Etete Road, GRA, Benin City. 
                    </p>
                </div>
            </div>
            <div className='address-wrap'>
            <Icon type="phone"/>
                <div className='title'>Phone Number</div>
                <div>
                    <p>09165656968; 09166122074</p>
                </div>
            </div>
            <div className='address-wrap'>
            <Icon type="whatsapp"/>
                <div className='title'>Chat us on Whatsapp</div>
                <div className='whatsapp-link'>
                    <a href='https://wa.link/9dlsaq' target="_blank" rel="noreferrer">
                    wa.link/9dlsaq
                    </a>
                </div>
            </div>
            <div className='address-wrap'>
            <Icon type="envelope"/>
                <div className='title'>Email Information</div>
                <div>
                    <p>info@vendexpress.ng</p>
                </div>
            </div>
        </div>
    )
}

export default Index
