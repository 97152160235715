import React from 'react';
import './style.css';
// import img from "../../../assest/images/man-avatar.png"

function Index() {
    return (
        <div className='testimonials-container container bg-light-green'>
            <div className='content-wrapper'>
            <div className='title flex test-flex'>
                 Why customers trust us
                </div>
            <div className='flex test-flex'>
                <div className='testimonial-card hover'>
                    <div className='text-wrap'>
                        <p>
                        " Your riders are so patient and humble with their customers. You guys are the absolute best I've come across so far. "
                        </p>
                        <div className='customer-info'>
                        {/* <img src={img} alt='user avatar' /> */}
                        <span>- Anita</span>
                    </div>
                    </div>
                </div>
                <div className='testimonial-card hover'>
                    <div className='text-wrap'>
                        <p>
                        "Vend Express Logistics changed my perspective on dispatch riders. I always get my packages on time and in one piece!Thank you for coming through."
                        </p>
                        <div className='customer-info'>
                        {/* <img src={img} alt='user avatar' /> */}
                        <span>- BShine Kitchen</span>
                    </div>
                    </div>
                </div>
                <div className='testimonial-card hover'>
                    <div className='text-wrap'>
                        <p>
                        “My products were delivered with effective and straight-forward with less time delivery”
                        </p>
                        <div className='customer-info'>
                        {/* <img src={img} alt='user avatar' /> */}
                        <span>- Khafeteria</span>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
    )
}

export default Index
