import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './modules/Home'
import About from './modules/About'
import Services from './modules/Services'
import Faqs from './modules/Faqs'
import Contact from './modules/Contact'
import Career from './modules/Career'
import TermsAndCondition from './modules/TermsAndCondition'


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="services" element={<Services />} />
        <Route path="faqs" element={<Faqs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="careers" element={<Career />} />
        <Route path="privacy" element={<TermsAndCondition />} />
      </Routes>
    </div>
  );
}

export default App;
