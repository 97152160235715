import React from 'react';
import "./style.css";
import customerImg from "../../../assest/images/professionals.png"

function Index() {
    return (
        <div className='container apply-container bg-blue'>
        <div className='apply-wrap content-wrapper'>
            <div className='flex'>
            <div className='img-wrap'>
                    <img src={customerImg} alt='vendex rider' />
                </div>
              <div>
              <div className='text-wrap'>
                    <p>
                    Join us on our Mission to provide timely and affordable logistics services with professionalism, integrity and excellent customer service.
                    </p>
                    <p className='marT-5'>
We are open to having honest, excellent and customer-oriented professionals work with us. 
                    </p>
                    <div className='btn-wrap'>
                    <a href='https://vendexpress.user.ng/rider/register'  target="_blank" rel="noreferrer">
                        <button className='started-btn'>Apply Here</button>
                    </a>
                    </div>
                    </div>
                    
              </div>
            </div>
            </div>
        </div>
    )
}

export default Index
