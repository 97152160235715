import React, { useState } from "react";
import "./style.css";
import Logo from "../../assest/images/logo.svg";
import { NavLink, Link } from "react-router-dom";
import Icon from "../../assest/Icons";

const loginBtn = <button className="loginBtn">Log In</button>;
const signupBtn = <button className="signupBtn started-btn">Sign Up</button>;
const items = [
  { label: "Home", key: "" },
  { label: "About", key: "about" },
  { label: "Services", key: "services" },
  { label: "Careers", key: "careers" },
  { label: "Contact", key: "contact" },
];

function Index() {
  const [showMenu, setMenuVisibility] = useState(false);
  const handleMenu = () => {
    setMenuVisibility(!showMenu);
  };

  return (
    <header>
      <nav className="content-wrapper">
        <div className="logo-wrap">
          <div className="logo">
          <Link to="/"><img src={Logo} alt="vendex logo" /></Link>
          </div>
          <div className="mobile-toggle" onClick={handleMenu}>
            {showMenu ? (
              <span className="close"><Icon type={'close'}/> </span>
            ) : (
              <span className="open"><Icon type={'menuIcon'}/> </span>
            )}
          </div>
        </div>
        <div>
          <ul className={`menu-list ${showMenu ? "" : "mobile-hidden"}`}>
            {items.map((item) => {
              return (
                <li key={item.key}>
                  <NavLink
                    className={({ isActive }) =>
                      `links ${isActive ? "selected" : undefined}`
                    }
                    to={`/${item.key}`}
                  >
                    {item.label}{" "}
                  </NavLink>
                </li>
              );
            })}
            <li>
              <a href="https://vendexpress.user.ng/login">{loginBtn}</a>
            </li>
            <li>
              <a href="https://vendexpress.user.ng/register">{signupBtn}</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Index;
