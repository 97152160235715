import React from 'react'
import { Link } from 'react-router-dom';
import "./style.css";
import footerLogo from "../../assest/images/footerlogo.svg"
import Icon from '../../assest/Icons';

function Index() {
    const resources = [
        {label: "Home", key:""},
        {label: "About", key:"about"},
        {label: "FAQs", key:"faqs"},
        {label: "Work With Us", key:"careers"},
        {label: "Contact", key:"contact"}
    ]
    return (
      <footer className="container footer-container">
        <div className="content-wrapper">
          <div className="footer flex">
            <div className="col">
              <div className="footer-logo">
              <Link to="/"> <img src={footerLogo} alt="footer logo" /> </Link>
              </div>
            </div>
            <div className="col">
              <h1>Resources</h1>
              <ul className="resource-track">
                {resources.map((item) => {
                  return (
                    <li key={item.key}>
                      <Link to={`/${item.key}`}>{item.label}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col last-col">
              <h1>Contact Us</h1>
              <ul>
                <li>
                  <a href="mailto:info@vendexpress.ng">Info@vendexpress.ng</a>
                </li>
                <li>
                  <a href="tel:09165656968">09165656968,</a>
                  &nbsp;
                  <a href="tel:09166122074">09166122074</a>
                </li>
                <li>
                  <span>
                    10, Guobadia Avenue, Off Etete Road, GRA, Benin City.
                  </span>
                </li>
                <li className="icon-wrap">
                  <a
                    href="https://twitter.com/vendexpressng"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span><Icon type={'twitter'} /></span>
                  </a>
                  <a
                    href="https://www.facebook.com/vendexpressng"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span><Icon type={'facebook'}/> </span>
                  </a>
                  <span>
                    <a
                      href="https://www.instagram.com/vendexpressng"
                      target="_blank"
                      rel="noreferrer"
                    >
                    <Icon type={'instagram'}/> 
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="reserved">
            <span>
              Vend Express Logistics 2022 All Rights Reserved | Terms of Service
              | <Link to="/privacy">Privacy Policy</Link>
            </span>
          </div>
        </div>
      </footer>
    );
}

export default Index
