import React from 'react'
import FaqCard from "../../Faqs/component/FaqCard"
import FaqHeader from "../../Faqs/component/FaqHeader"
import "./style.css"


function Index() {
    return (
        <div className='home-faq container content-wrapper'>
            <FaqHeader />
            <div className='home-faq-card flex'>
            <div className='faq-wrapper'>
            <FaqCard itemCount={4} />
            </div>
            </div>
            <div className='more'>
                <a href='/faqs'>View More</a>
            </div>
        </div>
    )
}

export default Index
