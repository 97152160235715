import React from 'react';
import successImg from "../../../assest/images/success.png";
import Modal from '../../../components/Modal';
import "./style.css"

const modalStyle = {
    "background": "#E3FFF3",
    "display": "flex",
    "justifyContent": "center",
    "alignItems": "center",
    "textAlign": "center"
}

function Index({show, onClose}) {
    return (
        <Modal show={show} onClose={onClose} modalStyle={modalStyle}>
        <div className='modal-content'>
            <div>
                <img src={successImg} alt="success" />
            </div>
                <h1>Thank You</h1>
                <p>for contacting Vend Express Logistics.
                    We will get back to you shortly." </p>
            </div>
        </Modal>
    )
}

export default Index
