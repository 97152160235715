import React from 'react';
import CoreValues from "./CoreValues";
import "./style.css";


function Index() {
    const items = [
        {label: "Vision", description: '"To be the household name for reliable logistics services in Benin City."'},
        {label: "Mission", description: '"To provide timely and affordable logistics services with professionalism, integrity and excellent customer service."'},
    ]
    return (
        <div className='container vision-container'>
            <div className='vision-wrap content-wrapper'>
            <div className='vision-flex'>
                {items.map((item) =>{
                    return (
                    <div key={item.label} className='item-wrap'>
                        <div className='title'>{item.label}</div>
                        <div className='vision-desc'><p>
                        {item.description}</p></div>
                    </div>
                    )
                })}
                 </div>
                <div className='core-values-container item-wrap'>
                    <div className='title'>Core Values</div>
                    <CoreValues />
                </div>
                <div>
                </div>
                <div>
                </div>
           
            </div>
        </div>
    )
}

export default Index
