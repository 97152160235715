import React from 'react'
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import History from './History'
import AboutUs from "./AboutUs"
import Vision from "./Vision"


function Index() {
    return (
        <div className='layout'>
            <Navbar />
            <div className='content'>
            <AboutUs />
            <History />
            <Vision />
            </div>
            <Footer/>
        </div>
    )
}

export default Index
