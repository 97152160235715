import React from 'react';
import "./style.css"

function Index() {
    return (
        <div className='faq-header'>
            <div className='title'>
                Frequently Asked Questions
            </div>
            <div>
                <p>Here are some common questions asked about Vend Express Logistics </p>
            </div>
        </div>
    )
}

export default Index
