import React from "react";
import "./style.css";
import mobile from "../../../assest/images/mobile-app.png";
import playstore from "../../../assest/images/playstore.svg";
import applestore from "../../../assest/images/applestore.svg";



function Index() {
  return (
    <div className="container download-mobile bg-dark-green">
      <div className="download-flex content-wrapper flex">
        <div className="text-wrap">
          <h1>Download Our Mobile App</h1>
          <div>
            <p>Just a click away from ease. </p>
            <p>Get the mobile app today!</p>
            </div>
          <div className="flex download-btn">
            <div>
            <img src={playstore} alt="play store" />
            </div>
            <div>
            <img src={applestore} alt="ios store" />
            </div>
          </div>
        </div>
        <div className="app-img">
            <div className="img-wrap mobile-img">
            <img src={mobile} alt="mobile app" />
            </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
