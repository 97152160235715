import React from 'react'
import Navbar from "../../components/Navbar"
import Footer from "../../components/Footer"
import ContactForm from "./ContactForm"
import ContactAddress from "./ContactAddress"
import "./style.css"


function Index() {
    return (
        <div className='layout'>
            <Navbar />
            <div className='content'>
            <div className='contact-container container'>
           
                <div className='row content-wrapper'>
                <div className='col'>
                <div className='contact-header'>
                        <h1>Contact Us</h1>
                    </div>
                <div className='contact-header'>
                   
                    <div>
                        <p>
                        We'd Like To Hear From You  - Got any questions or comments for us? 
                        We want to be of help to you.Fill out the form below and our Support Team will get to you soonest.
                        </p>
                    </div>
                </div>
                <ContactForm />
                </div>
                <div className='col bg-dark-green'>
                    <ContactAddress />
                </div>
                </div>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default Index
